/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import { graphql, Link } from 'gatsby';
import axios from 'axios';

// UI lib components
import { Container, Visible } from 'react-grid-system';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import PageTitle from '../page-components/page-title/index';
import FormMenu from '../helpers/FormMenu';
import ActualitiesBloc from '../shared/constants/actualities';

// APIs
import API from '../shared/APIs';

// Style
import '../page-styles/actualities.scss';

/* -------------------------------------------------------------------------- */
/*                       Actualities Details  Page                            */
/* -------------------------------------------------------------------------- */

function ActualitiesDetailsPage() {
  /* ********************************** HOOKS ********************************* */
  const [actualitiesData, setActualitiesData] = useState();
  const [loader, setLoader] = useState(true);
  const [searchInput, setSearchInput] = useState('');

  /* ********************************** CONST ********************************* */
  const getSearchInput = (event) => {
    setSearchInput(event.target.value);
  };

  /* ------------------------------ HELPERS --------------------------------- */
  useEffect(() => {
    axios
      .get(API('news'))
      .then(({ data: { data } }) => {
        setActualitiesData(data);
        setLoader(true);
      })
      .then(() => setLoader(false))
      .catch((error) => error);
  }, []);

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="Actualités" />
      <Container className="page-all-actualities">
        <Visible lg xl xxl>
          <Container className="top flex items-center fluid">
            <Link className="breadcrumb__link" to="/">
              Accueil
            </Link>
            <span className="m-r-l">/</span>
            <Link className="breadcrumb__link" to="/actualities">
              Actualités
            </Link>
          </Container>
        </Visible>
        <PageTitle title="Actualités" />
        <FormMenu getSearchInput={getSearchInput} />
        {/* <ActualitiesMenu getSearchInput={getSearchInput} /> */}
        <ActualitiesBloc
          actualitiesData={actualitiesData}
          loader={loader}
          searchInput={searchInput}
        />
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default ActualitiesDetailsPage;
